<template>
  <q-page padding>
      <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg" />
      <h4>Email Verified</h4>
    </q-card-section>
    <q-card-section>
      <div class="my-text">
        Your email has been verified
      </div>
      <br>
      <br>
      <div class="row justify-center items-center">
          <span class="my-text">
            <a id="registerLink" @click="navTo('login')">Back to Portal</a>
          </span>
      </div>
    </q-card-section>
  </q-card>
  </q-page>
</template>

<script>
export default {
  name: 'EmailVerified',
  methods: {
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name })
      }
    }
  }
}
</script>

<style>
  #registerLink {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
</style>
